
import { Component, Prop, Vue } from "vue-property-decorator";
import Welcome from "@/components/request/Welcome.vue";

@Component({
  components: {
    Welcome
  }
})
export default class WelcomeView extends Vue {}
